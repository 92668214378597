export const TABLE_PAGE_SIZE = 20;

export const SUPPORT_LANGUAGES = {
  EN: 'en',
  FI: 'fi',
  SV: 'sv',
};

export const DEFAULT_LANGUAGE = 'fi';

export const REPORTS_START_YEAR = 2020;
