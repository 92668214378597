import React from 'react';

type Props = { className?: string };

export default ({ className = '' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 495 230"
      className={className}
    >
      <g>
        <path d="M479.8 14.9v131.5c0 25.1-20.7 45.6-46.2 45.6H292.9c-16.9 0-32.9 6.1-45.4 17.4C235 198.2 219 192 202.1 192h-141c-25.3 0-45.8-20.4-45.8-45.6V14.9H479.8zM255 221.9c10.1-10.1 23.6-15.7 38-15.7h140.6c33.3 0 60.4-26.8 60.4-59.8V0.6H1v145.7c0 33 26.9 59.8 60.1 59.8h141c14.3 0 27.8 5.6 38 15.7l7.5 7.5L255 221.9z" />
        <path d="M425.7 74.8c0 4.4-3.5 7.6-7.9 7.6s-7.9-3.2-7.9-7.6 3.5-7.6 7.9-7.6C422.2 67 425.7 70.2 425.7 74.8M410.9 136.9h13.7V88.2h-13.7V136.9zM386.3 107.5l17.9 29.4h-14.9l-12-19.5 -6.5 8.2v11.3h-13.6v-70h13.6v32.3c0 6-0.7 12-0.7 12h0.3c0 0 3.2-5.3 5.9-9.1l10.4-13.9h16L386.3 107.5zM343.4 105.7c0-12.1-5.9-18.7-15.3-18.7 -8 0-12.2 4.7-14.6 9.2h-0.3l0.7-8h-13.6v48.7h13.6V108c0-5.6 3.3-9.5 8.4-9.5s7.3 3.4 7.3 9.9v28.5h13.7L343.4 105.7 343.4 105.7zM287.2 74.8c0 4.4-3.5 7.6-7.9 7.6s-7.9-3.2-7.9-7.6 3.5-7.6 7.9-7.6C283.8 67 287.2 70.2 287.2 74.8M272.4 136.9h13.7V88.2h-13.7V136.9zM248.8 123.3c0-3.9-5.4-4.7-11.7-6.5 -7.3-1.9-16.5-5.6-16.5-14.9 0-9.2 8.8-15 19.2-15 9.7 0 18.6 4.6 22.3 11.1l-11.7 6.6c-1.2-4.3-4.8-7.7-10.2-7.7 -3.5 0-6.4 1.5-6.4 4.4 0 3.7 6.4 4.1 13.6 6.4 7.6 2.4 14.6 5.9 14.6 14.8 0 9.8-9.1 15.6-19.6 15.6 -11.4 0-20.4-4.8-24.4-12.6l11.9-6.7c1.5 5.3 5.8 9.3 12.3 9.3C246.1 128.1 248.8 126.5 248.8 123.3M205.5 67.3h-13.7v56.1c0 5 1.1 8.7 3.2 11 2.2 2.3 5.6 3.4 10.3 3.4 1.6 0 3.3-0.2 4.9-0.5 1.7-0.4 3-0.9 4.1-1.5l1.2-9.2c-1.1 0.4-2 0.7-2.9 0.8 -0.8 0.2-1.8 0.2-2.9 0.2 -1.8 0-2.6-0.5-3.2-1.4 -0.7-0.9-1-2.5-1-4.8V67.3zM159.9 96.8c-5 0-9.1 3.6-10.2 10.2h19.2C169 101.2 165.2 96.8 159.9 96.8M181 116.2h-31.5c0.6 7.9 5 11.8 10.7 11.8 5.1 0 8.6-3.4 9.4-8l11.5 6.5c-3.4 6.2-10.7 11.6-20.9 11.6 -13.6 0-23.6-9.5-23.6-25.5 0-15.9 10.2-25.5 23.3-25.5s21.8 9.3 21.8 22.5C181.6 113.5 181 116.2 181 116.2M111.3 136.9h14.2V69.9h-14.2v26.5H84.6V70H70.4v66.9h14.2v-27.7h26.7V136.9z" />
      </g>
    </svg>
  );
};
