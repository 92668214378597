import React from 'react';

type Props = { className?: string };

export default ({ className = '' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 48 48"
      className={className}
    >
      <g stroke="none" fillRule="evenodd">
        <path d="M25.7840316,33.1986556 L25.7840316,29.7628702 L35.0639284,20.4829735 L32.4513652,17.8704103 L25.7840316,24.538441 L25.7840316,17.4849388 L31.3799913,11.8889791 L28.7674282,9.27641597 L23.9368352,14.107009 L19.1055451,9.27641597 L16.4936791,11.8889791 L22.0896387,17.4849388 L22.0896387,24.538441 L15.4223051,17.8704103 L12.809742,20.4829735 L22.0896387,29.7628702 L22.0896387,33.1986556 C15.1511506,32.2903926 9.77546043,26.3438141 9.77546043,19.1620537 C9.77546043,11.3529436 16.1277251,4.99998193 23.9368352,4.99998193 C31.7459453,4.99998193 38.0982099,11.3529436 38.0982099,19.1620537 C38.0982099,26.3438141 32.7225197,32.2903926 25.7840316,33.1986556 M41.7926028,19.1620537 C41.7926028,9.31614812 33.7827408,1.30628608 23.9368352,1.30628608 C14.0916266,1.30628608 6.08106753,9.31614812 6.08106753,19.1620537 C6.08106753,28.3840949 13.1087787,35.9945443 22.0896387,36.9223248 L22.0896387,47.4520416 L25.7840316,47.4520416 L25.7840316,36.9223248 C34.7648916,35.9945443 41.7926028,28.3840949 41.7926028,19.1620537" />
      </g>
    </svg>
  );
};
